.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: transparent;
}

.modal-main {
  position:fixed;
  background: #0E0032;
  width: 70%;
  max-width: 900px;
  height: 250px;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.modal-main .icon-close {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.modal-main .title {
  font-size: 40px;
  color: #FFFFFF;
  flex-grow: 0;
}

.modal-main .content {
  flex-grow: 1;
  width: 100%;
  font-size: 20px;
}

.modal-main .btn-group {
  flex-grow: 0;
}

.modal-main .btn-group .btn-primary {
  width: 300px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

@media (max-width: 600px) {
  .modal-main .content {
    font-size: 12px;
  }

  .modal-main .icon-close {
    right: 15px;
    top: 15px;
    width: 30px;
    height: 30px;
  }
}
