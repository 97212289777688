.my-domains {
    background: rgba(14, 0, 50, 0.75);
    text-align: center;
    padding: 40px 100px;
    width: 100%;
}

.my-domains-container {
    background: transparent;
    margin: auto;
    text-align: center;
    display: inline-flex;
    align-items: flex-start;
    gap: 20px;
    position: relative;
}

.my-domains-collections {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 20px;
    line-height: 30px;
    gap: 18px;
}

.my-domains-display {
    width: 600px;
    height: 700px;
    background: rgba(217, 217, 217, 0.2);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.my-domains-display .icon-close {
    display: none;
}

.my-domains-display .nft-image {
    width: 250px;
    height: 250px;
    margin: 45px 0px 20px 0px;
    border: 5px solid rgba(255, 255, 255, 0.5);
    border-radius: 35px;
}

.my-domains-display .nft-text {
    font-weight: 600;
    font-size: 40px;
    color: #FFFFFF;
    position: relative;
    display: inline-block;
}
  
.my-domains-display .nft-text .tooltiptext {
    visibility: hidden;
    width: 240px;
    background-color: #00000094;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    font-size: 15px;
    left: 18%;
    top: 100%;
    word-wrap: break-word;
}
  
.my-domains-display .nft-text:hover .tooltiptext {
    visibility: visible;
}

.my-domains-display .token-details {
    margin-top: 15px;
}

.my-domains-display .text-item {
    display: flex;
    align-items: baseline;
    margin: 15px;
}

.my-domains-display .heading {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    width: 120px;
    text-align: left;
    display: flex;
    align-items: center;
}

.my-domains-display .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;
    opacity: 0.5;
    display: flex;
    align-items: center;
}

.my-domains-display .text-item .icon-edit {
    cursor: pointer;
    display: inline-block;
    width: 16px;
    height: 16px;
}

.my-domains-display .text-item .icon-copy {
    cursor: pointer;
    display: inline-block;
    width: 16px;
    height: 18px;
}


.my-domains-display .btn-group {
    flex-direction: column;
}

.my-domains-display .btn-group .btn-primary {
    border: 0;
    background: #535AF7;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin: 6px;
    width: 300px;
}

.nft-container {
    height: 530px;
    overflow-y: auto;
    direction: rtl;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.my-domains-item {
    direction: ltr;
    min-height: 72px;
    width: 268px;
    color: white;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    background: rgba(217, 217, 217, 0.2);
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    position: relative;
}

.my-domains-item.empty-token {
    color: rgba(255, 255, 255, 0.5);
}

.nft-container .my-domains-item {
    cursor: pointer;
}

.my-domains-item .icon-arrow {
    width: 8px;
    height: 14px;
    right: 20px;
    position: absolute;
}

.my-domains-item-selected {
    background: rgba(255, 255, 255, 0.3);
    border: 2px solid #FFFFFF;
}

.my-domains .my-account-card {
    background: rgba(217, 217, 217, 0.2);
}

.my-domains .marketplace {
    margin: auto;
}

@media (max-width: 980px) {
    .my-domains-display {
        width: 400px;
    }

    .my-domains-item {
        width: 180px;
    }

    .my-domains .my-account-card {
        width: 190px;
        height: 112px;
        padding-left: 10px;
        gap: 10px;
    }

    .my-domains .my-account-card .nft-details .nft-details-heading {
        font-size: 12px;
        line-height: 15px;
        margin: 5px 0;
    }

    .my-domains .my-account-card .icon-logout {
        width: 15px;
        height: 15px;
    }

    .my-domains .my-account-card .icon-person {
        width: 45px;
        height: 45px;
    }
}

@media (max-width: 600px) {
    .my-domains {
        padding: 26px 26px;
        width: unset;
        border-radius: 14px;
    }

    .my-domains-container {
        display: block;
    }

    .my-domains-display {
        width: 290px;
        height: 640px;
        background: transparent;
    }

    .my-domains-display .icon-close {
        display: inline-block;
        position: absolute;
        right: -10px;
        top: -10px;
        width: 30px;
        height: 30px;
    }

    .my-domains-display .nft-image {
        width: 200px;
        height: 200px;
        margin: 40px 0px 20px 0px;
    }

    .my-domains-display .token-details {
        margin-top: 8px;
    }
    
    .my-domains-display .heading {
        font-size: 12px;
        line-height: 17px;
        width: 100px;
    }
    
    .my-domains-display .text-item .icon-edit {
        width: 15px;
        height: 15px;
    }

    .my-domains-display .text {
        font-size: 12px;
    }    

    .my-domains-display .nft-text {
        font-size: 20px;
    }

    .my-domains-display .btn-group .btn-primary {
        font-size: 17px;
        line-height: 28px;
        margin: 6px;
        width: 210px;
    }
    
    .nft-container {
        height: 470px;
    }

    .my-domains-item {
        font-size: 15px;
        width: 268px;
    }

    .my-domains .my-account-card {
        font-size: 15px;
        gap: 20px;
        width: 260px;
        padding-left: 28px;
    }

    .my-domains .my-account-card .nft-details .nft-details-heading {
        font-size: 15px;
        line-height: 21px;
        margin: 10px 0;
    }

    .my-domains .my-account-card .icon-logout {
        width: 20px;
        height: 20px;
    }

    .my-domains .my-account-card .icon-person {
        width: 65px;
        height: 65px;
    }
}
