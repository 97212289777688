
.icon-warn { background-image: url('../../../public/warn-icon.png') }
.icon-check { background-image: url('../../../public/check-icon.png') }

.modal .input-group {
	position: relative;
	width: fit-content;
}

.modal .input-group .icon {
	width: 30px;
	height: 30px;
	position: absolute;
	top: 25px;
	right: 20px;
}

.modal .address-display .icon {
  width: 20px;
  height: 20px;
  display: inline-block;
	vertical-align: bottom;
	margin-right: 8px;
}

.address-display p.warning {
	color: rgba(255, 189, 61, 1);
}

.modal .value-input {
	line-height: 50px;
  width: 800px;
  height: 70px;
  border-radius: 20px;
  text-align: center;
  font-size: 15px;
}

.modal .value-input::placeholder {
	opacity: 0.3;
}

@media (max-width: 600px) {
  .modal .input-group .icon {
    width: 20px;
    height: 20px;
    top: 25px;
    right: 10px;
  }

  .modal .value-input {
    width: 310px;
    font-size: 12px;
  }
}
