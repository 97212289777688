
.progress-circle {
    margin: auto;
    width: 50px;
    height: 50px;
}

.wrapper {
    width: 50px; /* Set the size of the progress bar */
    height: 50px;
    position: absolute; /* Enable clipping */
    clip: rect(0px, 50px, 50px, 25px); /* Hide half of the progress bar */
  }
/* Set the sizes of the elements that make up the progress bar */
.circle {
    width: 0px;
    height: 0px;
    border: 25px solid #ffff;
    border-radius: 25px;
    position: absolute;
    clip: rect(0px, 25px, 50px, 0px);
}
/* Using the data attributes for the animation selectors. */
/* Base settings for all animated elements */
div[data-anim~=base] {
	animation-iteration-count: 1;  /* Only run once */
	animation-fill-mode: forwards; /* Hold the last keyframe */
	animation-timing-function:linear; /* Linear animation */
}

.wrapper[data-anim~=wrapper] {
	animation-duration: 0.01s; /* Complete keyframes asap */
	animation-delay: 30s; /* Wait half of the animation */
	animation-name: close-wrapper; /* Keyframes name */
}

.circle[data-anim~=left] {
	animation-duration: 60s; /* Full animation time */
	animation-name: left-spin;
}

.circle[data-anim~=right] {
	animation-duration: 30s; /* Half animation time */
	animation-name: right-spin;
}
/* Rotate the right side of the progress bar from 0 to 180 degrees */
@keyframes right-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(180deg);
	}
}
/* Rotate the left side of the progress bar from 0 to 360 degrees */
@keyframes left-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
/* Set the wrapper clip to auto, effectively removing the clip */
@keyframes close-wrapper {
	to {
		clip: rect(auto, auto, auto, auto);
	}
}