
.mystery-box-container {
    background: #0e0032a8;
    border-radius: 22px;
    min-width: 940px;
    text-align: center;
    padding: 34px 0;
    position: relative;
}

.mystery-box-container h3 {
    background: linear-gradient(90deg, #96F7FF 21.99%, #91B7FF 41.21%, #D3A2FF 63.68%, #FFC2E4 81.03%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 30px;
    margin: 10px;
}

.mystery-box-description {
    margin: 38px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.mystery-box .icon {
	width: 23px;
    height: 23px;
    display: inline-block;
    vertical-align: bottom;
}

.mystery-box-container .link-close {
    position: absolute;
    right: 36px;
    top: 38px;
}
.mystery-box-container .icon-close {
    width: 46px;
    height: 46px;
}

.mystery-box .image,
.mystery-box .btn {
    margin: auto;
}

.mystery-box .btn-primary {
    padding-left: 7rem;
    padding-right: 7rem;
    font-size: 25px;
}

.img-mysterybox {
    background-image: url('../../../public/mysterybox.png');
    width: 250px;
    height: 272px;
    background-size: cover;
}

.mystery-box .mint-info {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.03em;
    margin-top: 25px;
    color: #5A62A7;
}

.mystery-box .mint-info .emphasize {
    font-weight: 600;
    color: #FFFFFF;
}

.mystery-box-container .marketplace-container {
    display: inline-block;
}

.mystery-box-open-section {
    display: flex;
    width: fit-content;
    margin: auto;
}
.mystery-box-open-container {
    position: relative;
    padding-top: 12px;
    margin: 0 25px 25px;
    border-radius: 10px;
}
.mystery-box-open-container > p {
    position: absolute;
    bottom: 0px;
    text-align: center;
    width: 100%;
}

.mystery-box-open-container:hover {
    background-color: #56516F;
}
.mystery-box-open-container > .btn {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: none;
}
.mystery-box-open-container:hover > .btn {
    display: block;
}
.mystery-box-open-container:hover > p,
.mystery-box-open-container:hover > .image {
    opacity: 0.3;
}

@media (max-width: 980px) {
	.mystery-box-container {
        min-width: 780px;
    }
}

@media (max-width: 800px) {
	.mystery-box-container {
        min-width: 580px;
    }
}

@media (max-width: 600px) {
	.mystery-box-container {
        min-width: 340px;
        padding: 13px 0;
    }

    .mystery-box-container h3 {
        font-size: 20px;
    }

    .mystery-box-description {
        margin: 20px;
        font-size: 12px;
        flex-direction: column;
    }

    .img-mysterybox {
        width: 200px;
        height: 218px;
    }

    .mystery-box-container .link-close {
        right: 20px;
        top: 20px;
    }
    .mystery-box-container .icon-close {
        width: 30px;
        height: 30px;
    }

    .mystery-box .btn-primary {
        font-size: 17px;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .mystery-box .mint-info {
        font-size: 10px;
        margin-top: 15px;
    }
}