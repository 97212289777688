.switch-network-container {
    width: 940px;
    /* height: 357px; */
    background: rgba(14, 0, 50, 0.75);
    border-radius: 22px;
    padding: 45px;
    box-sizing: border-box;
    text-align: center;
}

.switch-title {
    font-size: 40px;
}

.switch-text {
    margin-bottom: 45px;
}

.switch-network-container .btn {
    font-size: 15px;
    border-radius: 10px;
    width: 150px;
    margin: auto;
}

@media (max-width: 980px) {
    .switch-network-container {
        width: 780px;
    }
}

@media (max-width: 600px) {
    .switch-network-container {
        width: 340px;
    }

    .switch-title {
        font-size: 30px;
    }

    .switch-text {
        font-size: 15px;
    }
}