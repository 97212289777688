.my-account-card {
  background: rgba(14, 0, 50, 0.9);
  height: 152px;
  display: inline-flex;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  width: 260px;
  padding-left: 28px;
}

.my-account-card .icon-person {
  width: 65px;
  height: 65px;
  opacity: 0.5;
}

.my-account-card .icon-logout {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: bottom;
  cursor: pointer;
}

.my-account-card .nft-details .nft-details-heading {
  font-weight: 600;
  font-size: 15px;
  line-height: 21px;
  margin: 10px 0;
}

.my-account-card .nft-details .nft-details-heading a:visited {
  color: #56C2FF;
}
.my-account-card .nft-details .nft-details-heading.highlight {
  color: #56C2FF;
}

.my-account-card .nft-details .nft-details-text {
  font-weight: 300;
  font-size: 10px;
  line-height: 14px;
}