.icon-back { background-image: url('../../../public/left-icon.png') }

.registration {
    background: rgba(14, 0, 50, 0.75);
    text-align: center;
    padding: 40px 100px;
    min-height: 400px;
    width: 100%;
}

.registration .marketplace-container {
    display: inline-block;
    margin-left: 8px;
}

.registration h3 {
    background: linear-gradient(98.04deg, #89FFFF 6.74%, #449AFF 79.59%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-size: 35px;
    font-weight: 600;
}

.registration-steps {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: space-evenly;
    text-align: left;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
}

.registration-steps span {
    font-weight: 600;
}

.registration-steps > div {
    opacity: 0.3;
}
.registration-steps > div.active {
    opacity: 1;
}

.registration-content {
    width: 50%;
    margin: auto;
    min-width: 820px;
    position: relative;
}

.registration-summary {
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    padding: 32px;
    margin-top: 43px;
}

.registration-summary > span {
    display: inline-block;
    line-height: 55.5px;
    color: #ffffff;
}

.registration-variable {
    border: 3px solid #ffffff84;
    width: 200px;
    font-size: 25px;
    padding: 10px;
    border-radius: 12px;
    background: rgba(217, 217, 217, 0.3);
}

.registration-variable .unit {
    font-size: 10px;
}

.registration-variable.year {
    display: flex;
    justify-content: space-between;
}

.registration-variable.year > .operator {
    cursor: pointer;
    line-height: 20px;
    border: 2px solid #FFFFFF;
    display: inline-block;
    width: 25px;
    height: 25px;
    border-radius: 5px;
}

.registration-total {
    font-size: 45px;
    color: #FFFFFF;
}

.registration-description {
    width: 80%;
    padding: 30px;
    margin: auto;
    line-height: 28px;
}
.registration .btn {
    border-radius: 10px;
    font-size: 20px;
}

.registration .btn-primary {
    padding: 18px 55px;
    margin: auto;
    font-size: 20px;
}

.registration-price-tag {
    margin-top: 20px;
    font-size: 15px;
    color: rgba(86, 194, 255, 1);
}

.registration .sample-images {
    display: flex;
    width: fit-content;
    margin: auto;
}

.nft-image {
    width: 150px;
    height: 150px;
    background-size: cover;
    margin: 0 12px 42px;
    position: relative;
}

.nft-image > span {
    position: absolute;
    width: 100%;
    bottom: 13px;
    left: 0;
    font-size: 12px;
}

.nft-image.twit > span {
    color: rgba(28, 84, 158, 1);
}

.registration .btn-group {
    width: fit-content;
}

.registration .btn-group .btn-primary {
    margin: 0;
}
.registration .btn-group .btn-secondary {
    margin-right: 24px;
}

.domain-pill-container {
    margin-bottom: 40px;
}

.domain-pill {
    display: inline-block;
    padding: 10px 55px;
    border-radius: 20px;
    margin: 0 6px 12px;
}

@media (max-width: 980px) {

}

@media (max-width: 600px) {
    .registration {
        padding: 0 0 40px 0;
    }

    .registration h3 {
        font-size: 20px;
        font-weight: 400;
    }

    .registration-content {
        min-width: 100%;
    }

    .registration-summary {
        margin: 27px 16px;
        padding: 15px;
        border-radius: 15px;
    }

    .registration-summary > span {
        font-size: 7px;
    }

    .registration-variable {
        width: 90px;
        font-size: 20px;
        padding: 10px;
    }

    .registration-description {
        font-size: 10px;
        line-height: 14px;
    }

    .registration-total {
        font-size: 30px;
    }

    .registration .btn {
        font-size: 17px;
    }

    .registration-content .icon-back {
        position: absolute;
        width: 28px;
        height: 28px;
        top: 0;
        left: 18px;
    }
}